import React, { useMemo } from "react";

import {
	useViewContext,
	ViewContextProvider,
	CtaManagementProvider,
} from "@cta-management/context";

import { createAdminView, ViewComponent } from "@admin/view";

import List from "@cta-management/components/List";

import { AppDrawer } from "~/components/Modal";
import { FeedbackProvider } from "~/components/Feedback";

import { useAnalyticsCtaSummaries } from "@api/ctas";
import { companyColumns, formatCtaRow } from "@cta-management/data";
import dayjs from "dayjs";
import { TableType } from "@cta-management/types";
import { useSearch } from "~/components/Search";

const CtaManagementContent = () => {
	const { search } = useSearch();
	const {
		state: {
			modal: { content: modalContent, props: modalProps },
		},
		actions: { closeModal },
	} = useViewContext();

	const filter = useMemo(() => {
		const previousMonth = dayjs().month().toString();
		const currentYear = dayjs().year().toString();
		const toDate = dayjs().format();
		const fromDate = `${currentYear}-${previousMonth}-01T00:00:00+00:00`;

		return {
			from: fromDate,
			to: toDate,
		};
	}, []);

	const { analyticsCtaSummaryByCompany, companyAnalyticsLoading } =
		useAnalyticsCtaSummaries(filter);

	const analytics = useMemo(() => {
		return {
			rows: analyticsCtaSummaryByCompany
				.filter((row) =>
					row.companyName
						?.toLowerCase()
						.includes(search?.toLowerCase()),
				)
				.map(formatCtaRow),
			columns: companyColumns,
			loading: companyAnalyticsLoading,
		};
	}, [analyticsCtaSummaryByCompany, search]);

	return (
		<>
			<List tableType={TableType.Dealer} data={analytics} />
			{modalContent && (
				<AppDrawer {...modalProps} onClose={closeModal}>
					{modalContent}
				</AppDrawer>
			)}
		</>
	);
};

const CtaManagement: ViewComponent = ({ navigate, location }) => {
	return (
		<ViewContextProvider>
			<FeedbackProvider>
				<CtaManagementProvider navigate={navigate} location={location}>
					<CtaManagementContent />
				</CtaManagementProvider>
			</FeedbackProvider>
		</ViewContextProvider>
	);
};

export default createAdminView(CtaManagement, {
	title: "cta-management-dealer",
	internalPad: true,
	internalScroll: true,
});
